import 'core-js/stable';
import 'react-app-polyfill/ie11';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './Containers/App';
import i18n from './translations/i18n';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import Config from './Config';

import './sass/base.scss';

// Helper function to dynamically load the Intercom script
const loadIntercomScript = src => new Promise((resolve, reject) => {
  // Check if the script is already in the document
  if (document.querySelector(`script[src="${src}"]`)) {
    resolve();
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = src;

  script.onload = () => resolve();
  script.onerror = e => reject(new Error(`Failed to load Intercom script: ${e.message}`));

  document.head.appendChild(script);
});

// Check if Intercom is already initialized
const isIntercomLoaded = () => typeof window.Intercom === 'function';

// Dynamically load/update Intercom based on environment
async function initializeIntercom(envName) {
  const isProd = (envName === 'prod');
  const intercomAppId = isProd ? 'tlmqy4u1' : 'slathgp8';
  const intercomSrc = `https://widget.intercom.io/widget/${intercomAppId}`;

  // 2. Load (or re-check) the Intercom script
  try {
    await loadIntercomScript(intercomSrc);
  } catch (error) {
    console.error('Error loading Intercom script:', error);
    return;
  }

  // 3. Set or merge Intercom settings
  window.intercomSettings = {
    app_id: intercomAppId,
  };

  // 4. Boot or update Intercom
  if (isIntercomLoaded()) {
    // If Intercom is already initialized, reattach and update settings
    window.Intercom('reattach_activator');
    window.Intercom('update', window.intercomSettings);
  } else {
    // If Intercom isn't initialized, queue up commands
    const i = function (...args) { i.c(args); };
    i.q = [];
    i.c = function (args) { i.q.push(args); };
    window.Intercom = i;

    // Boot Intercom with the assigned settings
    window.Intercom('boot', window.intercomSettings);
  }
}

const AppWithIntercom = () => {
  useEffect(() => {
    // Initialize Intercom only when the component mounts
    initializeIntercom(Config.ENVIRONMENT_NAME);
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <AppWithIntercom />,
  document.getElementById('root')
);

registerServiceWorker();
